body{
    height: 100% !important;
    font-family: "Rubik", sans-serif !important;
    background-color: #f7f7fc;
    font-size: 16px !important;
    min-height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    flex: auto !important;
    margin: 0 !important;
}
  
main {
  font-family: Rubik;
    flex: auto !important;
    background-color: #f7f7fc;
}

footer {
     font-family: Rubik;
    width: 100% !important;
}

::-webkit-scrollbar {
    width: 10px !important;
    background: #1E2129 !important;
}
  
::-webkit-scrollbar-track {
    background: #1E2129 !important; 
}
   
::-webkit-scrollbar-thumb {
background: #0086FF !important; 
}
  
::-webkit-scrollbar-thumb:hover {
    background: #0086FF !important; 
}

.sorting-overflow {
    overflow: auto
}

.dragscrollul {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: auto
}

.dragscrollul::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    width: 1180px;
}

.dragscrollul::-webkit-scrollbar {
    display: none
}