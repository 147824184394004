.tourImg {
    visibility: visible !important;
}

.tourButton {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    padding: 8px 15px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: .3s ease;
    margin: 0 auto;
    display: block;
}

.tourButton:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
}

.tourButton2 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 5px;
    transition: .3s ease;
    margin: 0 auto;
    display: none;
}

.tourButton2:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .tourButton {
        display: none;
    }

    .tourButton2 {
        display: block;
    }
}

.panoramaViewer2 {
    width: 100%;
    height: 370px;
    margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .panoramaViewer2 {
        height: 460px;
    }

    .panoramaViewer {
        height: 480px;
    }
}

.psv--has-navbar .psv-gallery {
    overflow: hidden !important;
}

.psv-download-button {
    display: none;
}

.psv-caption {
    display: none;
}

.psv-menu-button {
    display: none;
}

.psv-gallery-container {
    flex-wrap: nowrap;
    overflow: scroll;
}
.psv-gallery-container::-webkit-scrollbar {
    display: none;
}