.BlogHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
}

.BlogCard {
    border-radius: 20px;
    box-shadow: none !important;
    position: relative;
    background: rgba(0, 0, 0, 0.25);
    width: 255px;
    height: 215px;
}

.BlogCardImg {
    position: relative;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 255px;
    height: 215px;
}

.BlogCardBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px;
}

.BlogCardTitle {
    position: absolute;
    top: 0;
    width: 210px;
    text-align: left;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}

.BlogCardDate {
    position: absolute;
    bottom: 0;

    width: 210px;
    text-align: left;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}


.BlogButtonL {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
    border-radius: 50% !important;
    color: #000 !important;
    position: absolute !important;
    left: -65px !important;
}

.BlogButtonR {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
    border-radius: 50% !important;
    color: #000 !important;
    position: absolute !important;
    right: -65px !important;
}

.BlogButton {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    padding: 15px 20px;
    border: 1px solid #0086FF;
    box-sizing: border-box;
    border-radius: 5px;
    transition: .3s ease;
}

.BlogButton:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
    border: 1px solid #33334F;
}

@media (min-width: 320px) and (max-width: 960px) {
    .BlogButtonL {
        background-color: #fff !important;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
        border-radius: 50% !important;
        color: #000 !important;
        position: absolute !important;
        left: -20px !important;
    }
    
    .BlogButtonR {
        background-color: #fff !important;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
        border-radius: 50% !important;
        color: #000 !important;
        position: absolute !important;
        right: -20px !important;
    }
}

@media (min-width: 320px) and (max-width: 639px) {
    .Fon {
        background: none;
    }
}