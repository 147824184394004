.callBoxContainer {
    position: fixed;
    right: 8%;
    bottom: 15%; 
    z-index:999;
    display: flex;
}

.callButton {
    width: 84px;
    height: 84px;
    /*background-color: #0086FF;*/
    /*box-shadow:0 8px 10px rgba(56, 147, 94,0.3);*/
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    //position: fixed;
    //right: 8%;
    //bottom: 15%;
    z-index:999;
    cursor:pointer;
    /*animation:hoverWave linear 1s infinite;*/
    display: block;
    /*border: 4px solid #0086FF;*/
    position: relative;
}

.callButton2 {
    width: 84px;
    height: 84px;
    /*background-color: #0086FF;*/
    /*box-shadow:0 8px 10px rgba(56, 147, 94,0.3);*/
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    //position: fixed;
    //right: 8%;
    //bottom: 15%;
    z-index:999;
    cursor:pointer;
    /*animation:hoverWave linear 1s infinite;*/
    display: none;
    position: relative;
}
  
@keyframes hoverWave {
    0% {
        box-shadow:0 8px 10px rgba(90,159,244,0.3),0 0 0 0 rgba(90,159,244,0.2),0 0 0 0 rgba(90,159,244,0.2)
    }
    40% {
        box-shadow:0 8px 10px rgba(90,159,244,0.3),0 0 0 15px rgba(90,159,244,0.2),0 0 0 0 rgba(90,159,244,0.2)
    }
    80% {
        box-shadow:0 8px 10px rgba(90,159,244,0.3),0 0 0 30px rgba(90,159,244,0),0 0 0 26.7px rgba(90,159,244,0.067)
    }
    100% {
        box-shadow:0 8px 10px rgba(90,159,244,0.3),0 0 0 30px rgba(90,159,244,0),0 0 0 40px rgba(90,159,244,0.0)
    }
}
  
@keyframes shake {
    0% {
        transform: rotateZ(0deg);
    }
    10% {
        transform: rotateZ(-30deg);
    }
    20% {
        transform: rotateZ(15deg);
    }
    30% {
        transform: rotateZ(-10deg);
    }
    40% {
        transform: rotateZ(7.5deg);
    }
    50% {
        transform: rotateZ(-6deg);
    }
    60% {
        transform: rotateZ(5deg);
    }
    70% {
        transform: rotateZ(-4.28571deg);
    }
    80% {
        transform: rotateZ(3.75deg);
    }
    90% {
        transform: rotateZ(-3.33333deg);
    }
    100% {
        transform: rotateZ(0deg);
    }
}
  
@media (min-width: 320px) and (max-width: 480.98px) {
    .callButton {
        //bottom: 8%;
        display: none;
    }

    .callButton2 {
        //bottom: 8%;
        display: block;
    }
}

.callBox {
    max-width: 280px;
    min-width: 180px;
    padding: 12px 15px 12px 15px;
    background-color: #fff;
    border: 2px solid #0086FF;
    color: #000;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    position: fixed;
    //right: 13%;
    //bottom: 15%;
    z-index:999;
    display: none;
    position: relative;
}

.callBoxTitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    margin: 0;
}

.callBoxDescription {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    margin: 0;
    margin-top: 5px;
}

.callBoxClose {
    position: absolute;
    top: 0px;
    right: 11px;
    cursor: pointer;
}

.callBoxLine {
    position: absolute;
    top: 45px;
    right: -7px;
    display: block;
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    background: #fff;
    border-right: 2px solid #0086FF;
    border-bottom: 2px solid #0086FF;
    border-color:  #0086FF;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .callBox {
        max-width: 185px;
        min-width: 185px;
        //right: 35%;
        //bottom: 5%;
    }

    .callBoxContainer {
        right: 5%;
        bottom: 9%; 
    }
}

