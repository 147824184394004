.Colorsection {
    background: #EBEFFF;
}

.SdelkaBox {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.SdelkaHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    max-width: 630px;
    min-width: 340px;
    position: relative;
}

.SdelkaHeader:before  {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}
  
.SdelkaHeaderDescription {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    margin-top: 30px;
}

.SdelkaContent {
    margin: 20px;
    width: 355px;
}


.SdelkaContent2 {
    margin: 0px;
}

.SdelkaImg {
    width: 84px;
    height: 84px;
}

.SdelkaHref {
    margin-top: 20px !important;
    text-decoration: none !important;
}

.SdelkaTextTitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    height: 57px;
}

.SdelkaTextDescription {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    margin-top: 10px;
    width: 255px;
}

.SdelkaCh {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
}

.SdelkaLinkOblako {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    background-color: #fff;
    margin-top: 20px;
    margin-left: 20px;
    padding: 5px 10px;
    border-radius: 8px;
}

.SdelkaLinkOblako:hover {
    text-decoration: none;
}
