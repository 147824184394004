.SocialHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
    font-family: Rubik;
}

.SocialHeader:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.Fon {
    position: relative;
}

.Color {
    position: absolute;
    top:0;
    left: -40%;
}

.socialOverflow {
    flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
}

.socialOverflow::-webkit-scrollbar {
  display: none;
}

.socialOverflow::-webkit-scrollbar-track {
   display: none;
}
 
.socialOverflow::-webkit-scrollbar-thumb {
   display: none;
}

.socialOverflow::-webkit-scrollbar-thumb:hover {
   display: none;
}

.SocialCard {
    border-radius: 20px;
    width: 360px !important;
    margin: 0 8px 0 8px !important;
    padding: 30px !important;
}

.SocialCardHeader {
    width: 308px !important;
}

.SocialCardLi {
    width: 360px !important;
    height: 353px !important;
    margin: 20px;
    position: relative;
    cursor: pointer;
    display:inline-block;
    margin-left: 0;
    margin-right: 20px;
}

.SocialButtonL {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
    border-radius: 50% !important;
    color: #000 !important;
    position: absolute !important;
    left: -65px !important;
}

.SocialButtonR {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
    border-radius: 50% !important;
    color: #000 !important;
    position: absolute !important;
    right: -65px !important;
}

.SocialLink {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #0086FF !important;
}

.SocialLink:hover {
    text-decoration: none;
}

.SocialButtonLink {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #FFFFFF !important;

    background: #0086FF;
    border-radius: 10px;
    padding: 8px 15px;
    transition: .3s ease;
    margin-left: 20px !important;
}

.SocialButtonLink:hover {
    background: #33334F !important;
    color: #fff !important;
    text-decoration: none;
}

.SocialCount {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    text-align: center;
}


@media (min-width: 320px) and (max-width: 960px) {
    .SocialButtonL {
        background-color: #fff !important;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
        border-radius: 50% !important;
        color: #000 !important;
        position: absolute !important;
        left: -20px !important;
    }
    
    .SocialButtonR {
        background-color: #fff !important;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
        border-radius: 50% !important;
        color: #000 !important;
        position: absolute !important;
        right: -20px !important;
    }

    .SocialCard {
        width: 330px !important;

    }

    .SocialCardLi {
        width: 330px !important;
    }

    .SocialButtonLink {
        margin-left: 15px !important;
    }

    .SocialCardHeader {
        width: 270px !important;
    }
}