.ProvHeader{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
    font-family: Rubik;
}

.ProvHeader:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.Fon {
    position: relative;
}

.Color {
    position: absolute;
    top:0;
    left: -35%;
}

.ProvContainer {
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
}

.ProvContainer::-webkit-scrollbar {
  display: none;
}

.ProvContainer::-webkit-scrollbar-track {
   display: none;
}
 
.ProvContainer::-webkit-scrollbar-thumb {
   display: none;
}

.ProvContainer::-webkit-scrollbar-thumb:hover {
   display: none;
}

@media (min-width: 320px) and (max-width: 959px) {
    .ProvContainer {
        flex-wrap: nowrap;
        width: 100%;
    }
}

.ProvTitle {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #000000 !important;
    position: relative;
    font-family: Rubik;
}

.ProvTitle:before  {
  content : "";
  position: absolute;
  left    : 0;
  bottom  : -10px;
  height  : 1px;
  width   : 50px; 
  border-bottom: 4px solid #0086FF;
}

.ProvDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin-top: 20px;
}

.ProvCh {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin-top: 20px;
}

.ProvButton {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    padding: 10px 20px;
    border: 1px solid #0086FF;
    box-sizing: border-box;
    border-radius: 5px;
    transition: .3s ease;
    display: block;
}

.ProvButton:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
    border: 1px solid #33334F;
}

.ProvButton3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    padding: 10px 20px;
    border: 1px solid #0086FF;
    box-sizing: border-box;
    border-radius: 5px;
    transition: .3s ease;
    display: none;
}

.ProvButton3:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
    border: 1px solid #33334F;
}

.ProvButton2 {
    width: 235px;
    height: 26px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #0086FF;
    margin-top: 20px;
}

.ProvButton2:hover {
    color: #0086FF;
    text-decoration: none;
}

.ProvBox {
    width: 252px;
    height: 265px;
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.ProvBoxM {
    margin-right: 30px;
    margin-bottom: 30px;
}

.ProvBoxLink:hover {
    text-decoration: none;
}

.ProvBoxSvg {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    height: 110px;
}

.ProvBoxText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #000000;
    position: relative;
    padding: 0 15px;
    height: 34px;
    margin: 0;
    margin-top: 10px;
    white-space: normal;
}

.ProvBoxText:after {
    content: "";
    display: block;
    margin: 0 auto;
    padding-top: 10px;
    border-bottom: 4px solid #0086FF;
    width: 100px;
}

.ProvBoxDescription {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #A1B4D2;
    padding: 0 10px;
    height: 55px;
    margin: 0;
    margin-top: 20px;
    white-space: normal;
}

.ProvBoxButton {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #0086FF;
    margin: 0;
    margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .ProvButton {
        display: none;
    }

    .ProvButton3 {
        display: block;
    }
    
}