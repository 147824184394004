.otzivSocialHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
}

.otzivSocialHeader:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.otzivSocialOverflow {
     flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    margin-top: 30px;
}

.otzivSocialOverflow::-webkit-scrollbar {
  display: none;
}

.otzivSocialOverflow::-webkit-scrollbar-track {
   display: none;
}
 
.otzivSocialOverflow::-webkit-scrollbar-thumb {
   display: none;
}

.otzivSocialOverflow::-webkit-scrollbar-thumb:hover {
   display: none;
}

.otzivSocialButton {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    border-radius: 50% !important;
    color: #000 !important;
}

.otzivSocialImg {
    border-radius: 0 0 20px 20px;
}

.otzivSocialLi {
    margin-right: 20px !important;
    margin: auto;
    display:inline-block;
}

.Fon {
    position: relative;
}

.Color {
    position: absolute;
    top:0;
    left: -35%;
}