.TextHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
}

.TextHeader:before {
    content : "";
    position: absolute;
    left    : 38px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.TextText {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    margin-top: 15px;
}

.TextH {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    margin-top: 15px;
}

.TextSvg {
    margin-bottom: 3px;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .TextMobileMargin {
        margin-bottom: 20px;
    }
}