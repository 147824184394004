.MapHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
}

.MapHeaderDescription {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0 !important;
    margin-top: 15px !important;
}

.MapFormImg {
    background: url(/img/map.png) center no-repeat;
    background-size: cover;
    padding: 50px;
}

.MapForm {
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 420px;
    padding: 20px;

    position: absolute;
    z-index: 1;
    left: 50px;
    top: 50px;
}

.MapFormTop15 {
    margin-top: 15px;
}

.MapImgPhone {
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 327px;
}

.MapFormPolicy {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 120%;
    text-align: left;
    letter-spacing: 0.1em;
    color: #000000;
    width: 295px !important;
    margin: 0;
}

.MapFormPolicyLink {
    text-decoration-line: underline;
    color: #0086FF;
}

.MapFormButton {
    width: 295px !important;
    height: 55px !important;
    background: #0086FF !important;
    box-shadow: 0px 5px 30px rgba(90, 159, 244, 0.3) !important;
    border-radius: 20px !important;

    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #FFFFFF !important;
    box-shadow: 0 4px rgba(0, 134, 255, 0.5) !important;
    border: none !important;
    transition: .3s ease;
}

.MapFormButton:hover {
    background: #33334F !important;
    color: #fff !important;
    box-shadow: 0 4px #33334F !important;
}

.MapFormTitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    margin: 0;
}

.MapFormSvg {
    margin-left: 15px;
}

.MapFormInputSvg {
    margin-top: 19px;
    margin-left: 15px;
    position: absolute;
}

.MapFormNumber {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #8D8E8F;
    margin: 0 !important;
}

@media (min-width: 768px) and (max-width: 991.98px) { 
    .MapForm {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) { 
    .MapForm {
        display: none;
    }

    .MapImgPhone {
        display: none;
    }
}

@media (min-width: 481px) and (max-width: 575.98px) {
    .MapForm {
        display: none;
    }

    .MapImgPhone {
        display: none;
    }
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .MapForm {
        display: none;
    }

    .MapImgPhone {
        display: none;
    }

    .MapMobileHiden {
        display: none;
    }
}

.map-selector input {
    margin:0;
    padding:0;
    appearance:none;
}

.map-selector input:active +.MapLink, .map-selector input:active +.MapLink{
    box-shadow: 0 0 20px 5px rgba(197,199,188,.3);
}
.map-selector input:checked +.MapLink, .map-selector input:checked +.MapLink{
    box-shadow: 0 0 20px 5px rgba(197,199,188,.3);
}

.MapLink {
    font-style: normal;
    font-weight: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    background: #fff;
    border-radius: 10px;
    width: 85px;
    height: 75px;
    cursor: pointer;
}

.MapLink:last-child {
    font-style: normal;
    font-weight: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
    background: #fff;
    border-radius: 10px;
    width: 85px;
    height: 75px;
    cursor: pointer;
}

.MapLink:first-child {
    margin-left: 0px;
}

.MapLink:hover {
    text-decoration: none;
    color: #000000;
    box-shadow: 0 0 20px 5px rgba(197,199,188,.3);
}

.MapLinkText {
    margin-top: 10px;
}

.MapLinkText:hover {
    text-decoration: none;
    color: #000000;
}


#ya-map {
    filter: grayscale(1) !important;
    -ms-filter: grayscale(1) !important;
    -webkit-filter: grayscale(1) !important;
    -moz-filter: grayscale(1) !important;
    -o-filter: grayscale(1) !important;
}

.contactFormContainer {
    border-radius: 20px;
}

.contactFormTitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    margin-top: 40px;
}

.contactFormDescription {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #8D8E8F;
    margin-top: 20px;
}

.contactMapFormTop15 {
    margin-top: 15px;
}

.contactMapImgPhone {
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 202px;
}

.contactMapFormPolicy {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #000000;
    width: 295px !important;
    margin: 0;
}

.contactMapFormPolicyLink {
    text-decoration-line: underline;
    color: #0086FF;
}

.contactMapFormButton {
    width: 295px !important;
    height: 55px !important;
    background: #0086FF !important;
    box-shadow: 0px 5px 30px rgba(90, 159, 244, 0.3) !important;
    border-radius: 20px !important;

    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #FFFFFF !important;
    box-shadow: 0 4px rgba(0, 134, 255, 0.5)  !important;
    border: none !important;
    transition: .3s ease;
}

.contactMapFormButton:hover {
    background: #33334F !important;
    color: #fff !important;
    box-shadow: 0 4px #33334F !important;
}

.contactMapFormInput {
    outline: none !important;
    width: 245px !important;
    height: 55px !important;
    background: #f7f7fc !important;
    border-radius: 5px !important;
    padding-left: 70px;
    color:#666 !important;
    font-size: 13px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    border: 1px solid rgba(204, 204, 204, 0.2%);
}

.contactMapFormInput:focus {
  outline: none !important;
  border: 1px solid rgba(204, 204, 204, 0.2%)!important;
}

.contactMapFormInputNum {
    margin-top: 20px;
    margin-left: 45px;
    position: absolute;
    font-style: normal !important;
    letter-spacing: .1em !important;
    font-size: 13px !important;
    color: #666;
}

.contactMapFormTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #666;
    margin: 0;
    margin-top: 20px;
}

.contactMapFormSvg {
    margin-left: 15px;
}

.contactMapFormInput:-ms-input-placeholder {
    color: #666 !important;
}

.contactMapFormInput:placeholder {
    color: #666 !important;
}

.contactMapFormInputSvg {
    margin-top: 20px;
    margin-left: 15px;
    position: absolute;
}

.contactMapFormNumber {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0 !important;
}

.mapCityBg {
    position: absolute;
    bottom: 0px;
    left: 0;
    object-fit: cover;
    z-index: -1;
}
