/* Story */
.sliderHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    margin: 0;
}

.Fon {
    position: relative;
}

.FonRemoveBottom {
    padding-bottom: 0 !important;
}

@media (min-width: 320px) and (max-width: 1870px) {
    .Color {
        display: none;
    }
}

.sliderButton {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    border-radius: 50% !important;
    color: #000 !important;
}

.sliderImg {
    margin: 0 0 15px 0;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0,0,0,.08);
}

.sliderLi {
    margin-left: 20px !important;
}

@media (min-width: 320px) and (max-width: 639px) {
    .sliderLi {
        margin: 0 10px 0 10px;
    }
}
/* Story */