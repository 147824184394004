.Colorsection {
    background: #EBEFFF;
}

.CalcContainer {
    width: 100%;
    overflow: visible;
    white-space:nowrap;
    flex-direction: column;
}

.CalcContainer::-webkit-scrollbar {
  display: none;
}

.CalcContainer::-webkit-scrollbar-track {
   display: none;
}
 
.CalcContainer::-webkit-scrollbar-thumb {
   display: none;
}

.CalcContainer::-webkit-scrollbar-thumb:hover {
   display: none;
}

.CalcMtop {
    margin-top: 20px;
}

.calc-click{
    position: absolute;
    right: -6px;
    bottom: -10px;
    width: 18px;
    height: 18px;
    display: block;
}

@media (min-width: 320px) and (max-width: 959px) {
    .CalcContainer {
        overflow: auto;
        flex-direction: row;
        margin-top: 20px;
    }

    .calc-click {
        display: none;
    }

    .CalcRangeBoxH {
        display: none;
    }

    .CalcMtop {
        margin-top: 0px;
    }
}

.switcher-containerDDD {
    margin-top: 0px !important;
}

.CalcTitle {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #33334F !important;
    position: relative;
}

.CalcTitle:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.calcTitleSize {
    font-size: 24px !important;
}

.CalcTitle2 {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    letter-spacing: 0.1em !important;
    color: #33334F !important;
    position: relative;
}

.CalcDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (min-width: 320px) and (max-width: 768px) {
    .CalcTitle2 {
        margin-top: 20px;
    }
}

.CalcTitle3 {
    color: #0086FF;
    background: rgba(0, 134, 255, 0.10);
    border-radius: 5px;
    padding: 4px 8px;
    font-weight: 500;
}

.CalcRangeBox {
    margin-top: 20px;
}

.CalcRangeBox:first-child {
    margin-top: 0px;
}

.CalcText {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
}

.CalcInputBox {
    position: relative;
    width: 290px;
}

.CalcInput {
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    background: none;
    border: none;
    width: 290px;
}

.CalcInputRub {
    position: absolute;
    top: 10px;
    right: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.CalcSumma {
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #0086FF;
}

.CalcBoxText2 {
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #9D9D9D;
    width: 210px;
}

.CalcBoxText3 {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #000000;
}


.CalcBoxAva {
    width: 55px;
    height: 55px;
}

.CalcButton {
    display: inline-block;
    margin: 0;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #FFFFFF !important;
    padding: 10px 12px !important;
    text-transform: none !important;
    background: #0086FF !important;
    border-radius: 5px !important;
    transition: .3s ease;
}

.CalcButton:hover {
    background: #33334F !important;
    color: #fff !important;
    text-decoration: none;
}

.CalcButton2 {
    display: none;
    margin: 0;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #FFFFFF !important;
    padding: 10px 12px !important;
    text-transform: none !important;
    background: #0086FF !important;
    border-radius: 5px !important;
    transition: .3s ease;
}

.CalcButton2:hover {
    background: #33334F !important;
    color: #fff !important;
    text-decoration: none;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .CalcButton {
        display: none;
    }

    .CalcButton2 {
        display: inline-block;
    }
}

.CalcRange {
    -webkit-appearance: none;
    width: 290px;
    height: 1px;
    border-radius: 5px;
    background: #C4C4C4;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin-top: 10px;
}
  
.CalcRange:hover {
    opacity: 1;
}
  
.CalcRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    background: #0086ff;
    box-shadow: 0px 5px 20px rgba(0, 134, 255, 0.5);
    box-sizing: border-box;
}
  
.CalcRange::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    background: #FFFFFF;
    border: 2px solid #0086ff ;
    box-sizing: border-box;
    cursor: pointer;
}

.CalcFon {
    background: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,.08);
    border-radius: 10px;
    margin: 10px !important;
    padding: 0px !important;
    display:inline-block;
    cursor: pointer;
}

.CalcPolicy {
    margin: 0 auto;
    margin: 10px !important;
    padding: 0px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
}

.CalcAva {
    width: 45px;
    height: 45px;
}

.CalcBank{
    margin: 0;
    margin: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000;
    width: 130px;
    text-align: center;
}

.CalcTitleMin {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #000000 !important;
    position: relative;
    margin-top: 20px;
}

.CalcTitleMin:before  {
  content : "";
  position: absolute;
  left    : 0;
  bottom  : -10px;
  height  : 1px;
  width   : 50px; 
  border-bottom: 4px solid #349EFF;
}

.CalcDescriptionMin {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
}

.CalcDescriptionMin2 {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin-top: 20px;
}

.CalcBank2{
    margin: 0;
    margin: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000;
    width: 215px;
}

.Calc2 {
    margin: 0;
    margin: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000;
    width: 110px;
    text-align: center;
}

.Calc3 {
    margin: 0;
    margin: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000;
    width: 50px;
    text-align: center;
}

.Calc4 {
    margin: 0;
    margin: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000;
    width: 110px;
    text-align: center;
}

.DelaemDvasHeaderLinks {
    overflow: auto;
    white-space: nowrap;
    flex-wrap: nowrap !important;
    margin-left: 0px !important;
}

.calcBoxLink {
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    font-size: 13px !important;
    margin-right: 20px;
    text-transform: uppercase;
    color: #999;
    border-bottom: 1px solid transparent;
    transition: color .1s ease-in-out;
}

.calcBoxLink:hover {
    text-decoration: none;
    color: #000;
}

.calc-info-box {
    background: #33334F;
    border-radius: 10px;
    top: 30px;
    width: 320px;
    display: none;
    padding: 20px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
}

.calc-info-box2 {
    background: #33334F;
    border-radius: 10px;
    top: 30px;
    width: 320px;
    display: none;
    padding: 20px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
}

.calc-info-bold {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
}

.calc-info-title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    border-bottom: 1px dashed #fff;
}

.calc-info-title2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    margin-top: 3px;
}

.calc-info-hr {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}


.calc-info-help-box {
    position: relative;
    margin-top: 15px;
}

.calc-info-help {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000;
    border-bottom: 1px dashed #000; 
    cursor: pointer;
}

.calc-operator {
    margin-top: 20px !important;
}

.calcButton {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    border-radius: 10px;
    padding: 8px 13px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0086FF;
    background: rgba(0, 134, 255, 0.07);
}

.calcButton:hover {
    text-decoration: none;
    color: #0086FF;
}

@media (min-width: 320px) and (max-width: 392px) {
   .calcButton {
    margin-top: 30px;
   } 
}

.calcButtonSvg {
    margin-left: 8px;
}


.slideBoxText2 {
    margin: 0;
    margin-top: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #938e8e;
}

.slideBoxText2Link {
    margin: 0;
    margin-top: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #938e8e;
    position: relative;
}

.slideBoxText2LinkSvg {
    position: absolute;
    right: -10px;
    top: -2px;
}

.slideBoxText2Link:hover {
    text-decoration: none;
    color: #938e8e;
}

.slideBoxText3 {
    margin: 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #000000;
}

.slideBoxText4 {
    margin: 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #fff;
    background: #33334F;
    border-radius: 10px;
    width: 150px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 6px;
    margin-top: 5px;
    transition: .3s ease;
}

.slideBoxText4:hover {
    text-decoration: none;
    color: #fff;
    background: #0086ff;
}

.slideBoxAva {
    width: 64px;
    height: 64px;
    border-radius: 50%;

    border: 5px double #fff;
    background: #0086FF;
    padding: 0px;
}

.slideIpotekaBox2 {
    width: 230px;
    margin-top: 5px;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .slideIpotekaBox2 {
        margin-top: 15px;
    }

    .CalcDescription {
      margin-top: 20px;
    }
}

.headerSearchBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
}

.headerSearchBoxLinkActive {
    color: #000 !important;
    padding-bottom: 10px;
    display: block;
    position: relative;
}

.headerSearchBoxLinkActive:before {
    position: absolute;
    content: '';
    border-bottom: 1px solid #0086FF;
    width: 100%;
    display: block;
    bottom: 0px;
}

.sorting-overflow {
    overflow: auto;
}

.dragscrollul {
    white-space: nowrap;
    flex-wrap: nowrap;
}