.dronImg {
   visibility: visible !important;
}

.dronReverse {
    flex-wrap: wrap-reverse;
}

.KlientHeader2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    max-width: 540px;
    min-width: 340px;
    position: relative;
}

.KlientHeader2:before  {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.DronContainer {
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
}

@media (min-width: 320px) and (max-width: 959px) {
    .DronContainer{
        width: 100%;
        flex-wrap: nowrap;
    }
}

.DronContainer::-webkit-scrollbar {
  display: none;
}

.DronContainer::-webkit-scrollbar-track {
   display: none;
}
 
.DronContainer::-webkit-scrollbar-thumb {
   display: none;
}

.DronContainer::-webkit-scrollbar-thumb:hover {
   display: none;
}

.dronDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin-top: 20px;
}

.dronDescription a {
    color: #0086FF;
}

.dronDescription a:hover {
    text-decoration: none;
    color: #0086FF;
    text-decoration-line: underline;
}

.KlientContent2 {
    margin: 10px;
    margin-top: 20px;
    width: 265px;
    display:inline-block;
}

.Colorsection {
    background: #EBEFFF;
}

.DronText {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    width: 300px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.DronText2 {
    margin: 0;
    margin-left: 10px;
}

.DronText3 {
    margin: 0;
    margin-left: 8px;
}

.DronLi {
    margin: auto;
    margin-top: 30px;
    display: inline-block;
}


.KlientTextSpan {
    color: #0086FF;
}

.DronButton {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    padding: 8px 15px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: .3s ease;
    margin: 0 auto;
    display: block;
    margin-top: 10px;
}

.DronButton:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
}

.DronButton2 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    box-sizing: border-box;
    border-radius: 10px;
    transition: .3s ease;
    margin: 0 auto;
    display: none;
    margin-top: 10px;
    padding: 8px 15px;
}

.DronButton2:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
}

.KlientTextTitle2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    margin-top: 20px;
    height: 38px;
    white-space: normal;
}

.KlientTextDescription2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    margin-top: 10px;
    width: 255px;
    white-space: normal;
}

.KlientImg2 {
    width: 60px;
    height: 60px;
}

.KlientImgL2 {
    width: 82px;
    height: auto;
}

.KlientImgL3 {
    width: 63px;
    height: auto;
}

.KlientImgY {
    width: 510px;
    height: auto;
    margin: 0 auto;
}

.KlientImgYX {
    width: 443px;
    height: auto;
    margin: 0 auto;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .KlientTextDescription {
        width: 225px;
    }

    .KlientImgL {
        width: auto;
        height: auto;
    }

    .DronButton {
        display: none;
    }

    .DronButton2 {
        display: block;
    }
}

.MobileImg {
  width: 115px;
  height: 38px;
}