.geoTitle-h2 {
	font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    margin: 0;
    position: relative;
}

.geoTitle-h2:before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -10px;
    height: 1px;
    width: 50px;
    border-bottom: 4px solid #0086FF;
}

.modalGeo {
	display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity .15s linear;
  width: 100%;
  height: 100%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .modalGeo {
    width: 100%;
  }
}

.modalGeoX {
	font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000;
    cursor: pointer;
}

.modalGeoBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;

  width: 420px;
  background-color: #fff;
  color: #000;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.35);
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 999;

  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 20px;
  transform: translateY(0);
  transition: .3s linear;
  transition-property: opacity,transform;
}

.modalGeoTitleBox {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.modalGeoTitle {
	font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
}

.modalGeoDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #9D9D9D;
}

.modalGeoDescription2 {
	font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 160%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.modalGeoContent {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}

.modalGeoContentBox {
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	border: 1px solid #9D9D9D;
	width: 104px;
	height: 104px;
	margin-top: 20px;
	margin-right: 20px;
}

.modalGeoContentBox2 {
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	border: 1px solid #9D9D9D;
	width: 104px;
	height: 70px;
	margin-top: 20px;
	margin-right: 20px;
}

.modalGeoContentText1 {
	font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    text-align: center;
    margin-top: 10px;
}

.modalGeoContentText2 {
	font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000;
    text-align: center;
    margin-top: 5px;
}

.modalGeoContentText3{
	margin: 0 auto;
	height: 30px
}


.modalGeoContentText4{
	font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #9D9D9D;
    text-align: center;
}

.modalGeoPriceBox {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	border-radius: 15px;
	border: 1px solid #9D9D9D;
	padding: 15px;
}

.modalGeoPriceBoxText {
	font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modalGeoPriceBoxText2 {
	font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modalGeoPriceBoxTextBorder {
	font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #0086FF;
    background: rgba(0, 134, 255, 0.07);
    border-radius: 8px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.modalGeoSelectBtn {
	font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
	color: #0086FF;
	position: relative;
    display: inline-block;
	cursor: pointer;
	border-bottom: 1px dashed #0086FF;
	padding-bottom: 3px;
}

.modalGeoSelectBtn:hover {
	text-decoration: none;
}

.modalGeoSelect {
  position: relative;
  display: inline-block;
}

.modalGeoSelectContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 15px;
  overflow: auto;
  height: 250px;
}

.modalGeoSelectLink {
  color: black;
  text-decoration: none;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #000;
}

.modalGeoSelectLink2 {
  color: black;
  text-decoration: none;
  display: block;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #000;
}

.modalGeoSelectContent  a:hover {
	text-decoration: none;
	color: #0086FF;
}

.modalGeoSelect:hover .modalGeoSelectContent  {
  display: block;
  text-decoration: none;
}

.modalGeoSelect:hover .modalGeoSelect {
  color: #0086FF;
  text-decoration: none;
}

.modalGeoError {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
  letter-spacing: 0.1em;
  color: #938e8e;
  position: relative;
}

@media (min-width: 320px) and (max-width: 480px) {
  .modalGeoContent {
    justify-content: center;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .modalGeoBox {
    width: 345px;
  }
}

