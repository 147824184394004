.KlientHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    max-width: 540px;
    min-width: 340px;
    position: relative;
    margin-bottom: 40px;
}

.KlientHeader:before  {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.KlientContainer {
    flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
}

.KlientContainer::-webkit-scrollbar {
  display: none;
}

.KlientContainer::-webkit-scrollbar-track {
   display: none;
}
 
.KlientContainer::-webkit-scrollbar-thumb {
   display: none;
}

.KlientContainer::-webkit-scrollbar-thumb:hover {
   display: none;
}

.KlientContent {
    margin: 20px;
    width: 355px;
}

@media (min-width: 320px) and (max-width: 959px) {
    .KlientContainer {
        flex-wrap: nowrap;
        margin-top: 20px !important;
    }

    .KlientContent {
        display:inline-block;
    }

    .KlientTextTitle {
        margin-top: 20px !important;
    }
}

.Colorsection {
    background: #EBEFFF;
}

.KlientText {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    width: 300px;
    margin: 0 auto;
    margin-top: 20px;
}

.KlientText2 {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    width: 300px;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
}

.KlientTextSpan {
    color: #0086FF;
}

.KlientButton {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    box-sizing: border-box;
    border-radius: 10px;
    transition: .3s ease;
    margin: 0 auto;
    margin-top: 20px;
    display: block;
    padding: 8px 15px;
}

.KlientButton:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
}

.KlientButton2 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #0086FF;
    padding: 8px 15px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: .3s ease;
    margin: 0 auto;
    margin-top: 10px;
    display: none;
}

.KlientButton2:hover {
    text-decoration: none;
    background: #33334F !important;
    color: #fff !important;
}

.KlientTextTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    white-space: normal;
}

.KlientTextDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
    margin-top: 10px;
    width: 255px;
    white-space: normal;
}

.KlientImg {
    width: 84px;
    height: 84px;
}


.KlientImgL {
    width: 349px;
    height: 349px;
    margin: 0 auto;
    display: block;
    animation: move 5s ease-in-out infinite;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .KlientTextDescription {
        width: 225px;
    }

    .KlientImgL {
        width: 180px;
        height: 180px;
    }

    .KlientButton {
        display: none;
    }

    .KlientButton2 {
        display: block;
    }
}

.ypakovkaImg {
    visibility: visible !important;
}