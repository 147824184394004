.otzivAudioHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
}

.otzivAudioHeader:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.otzivAudioCard {
    border-radius: 20px;
    width: 577px !important;
    height: 340px !important;
}

.Fon {
    background-size: 300px auto;
}

.otzivAudioOverflow {
    flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
}

.otzivAudioOverflow::-webkit-scrollbar {
  display: none;
}

.otzivAudioOverflow::-webkit-scrollbar-track {
   display: none;
}
 
.otzivAudioOverflow::-webkit-scrollbar-thumb {
   display: none;
}

.otzivAudioOverflow::-webkit-scrollbar-thumb:hover {
   display: none;
}

.otzivAudioCardLi {
    width: 577px !important;
    height: 340px !important;
    margin: 20px 0px 20px 0px;
    margin-left: 0;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    display:inline-block;
}

.imgLine1 {
    width: 4px !important;
    height: 150px !important;
    background-color: #6890F1;
    position: absolute;
    top: 30px;
    left: 0;
    border-radius: 10px;
}

.imgLine2 {
    width: 4px !important;
    height: 150px !important;
    background-color: #FA4B8C;
    position: absolute;
    top: 30px;
    left: 0;
    border-radius: 10px;
}
.imgLine3 {
    width: 4px !important;
    height: 150px !important;
    background-color: #8660FE;
    position: absolute;
    top: 30px;
    left: 0;
    border-radius: 10px;
}
.imgLine4 {
    width: 4px !important;
    height: 150px !important;
    background-color: #36BEDF;
    position: absolute;
    top: 30px;
    left: 0;
    border-radius: 10px;
}
.imgLine5 {
    width: 4px !important;
    height: 150px !important;
    background-color: #34C15C;
    position: absolute;
    top: 30px;
    left: 0;
    border-radius: 10px;
}

.otzivAudioImg {
    display: inline-block !important;
    width: 139px !important;
    height: auto !important;
    max-width: none !important;
}

.otzivAudioDescription {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #454545;
    margin: 0;
    width: 305px;
    height: 190px;
    white-space: normal;
}

.otzivAudioTitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0;
}

.otzivAudioTitleLine {
    position: relative;
    border-bottom: 4px solid rgba(0, 134, 255, 0.1);
    display: inline-block;
    padding-bottom: 0px;
}

.otzivAudioName {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #0086FF;
    margin: 0;
    text-align: center;
    margin-top: 15px;
}

.otzivAudioAudio { 
    background-color: #fff !important;
    background: #fff !important;
    background:linear-gradient(to top left, #fff, #fff);
  }

.otzivAudioAudio::-webkit-media-controls-panel {
    background:linear-gradient(to top left, #fff, #fff);
}

.otzivAudioButtonL {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
    border-radius: 50% !important;
    color: #000 !important;
    position: absolute !important;
    left: -65px !important;
}

.otzivAudioButtonR {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
    border-radius: 50% !important;
    color: #000 !important;
    position: absolute !important;
    right: -65px !important;
}

.otzivAudioCity {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #A1B4D2;
}

.otzivMobileCardMargin {
    position: relative;
}

.otzivMobileCardMarginImg {
    position: absolute !important;
    top: 0 !important;
    left: 80px !important;
    width: 225px;
    height: 225px;
}

@media (min-width: 320px) and (max-width: 960px) {
    .otzivAudioButtonL {
        background-color: #fff !important;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
        border-radius: 50% !important;
        color: #000 !important;
        position: absolute !important;
        left: -20px !important;
    }
    
    .otzivAudioButtonR {
        background-color: #fff !important;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
        border-radius: 50% !important;
        color: #000 !important;
        position: absolute !important;
        right: -20px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .otzivAudioCard {
        width: 430px !important;
        height: 510px !important;
    }
    
    .otzivAudioCardLi {
        width: 430px !important;
        height: 510px !important;
    }

    .otzivAudioDescription {
        width: 100%;
        height: 170px;
        text-align: center;
    }

    .otzivAudioTitle {
        text-align: center;
    }

    .otzivMobileCardMargin {
        margin-top: 0px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) { 
    .otzivAudioCard {
        width: 430px !important;
        height: 510px !important;
    }
    
    .otzivAudioCardLi {
        width: 430px !important;
        height: 510px !important;
    }

    .otzivAudioDescription {
        width: 100%;
        height: 170px;
        text-align: center;
    }

    .otzivAudioTitle {
        text-align: center;
    }

    .otzivMobileCardMargin {
        margin-top: 0px !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) { 
    .otzivAudioCard {
        width: 430px !important;
        height: 510px !important;
    }
    
    .otzivAudioCardLi {
        width: 430px !important;
        height: 510px !important;
    }

    .otzivAudioDescription {
        width: 100%;
        height: 170px;
        text-align: center;
    }

    .otzivAudioTitle {
        text-align: center;
    }

    .otzivMobileCardMargin {
        margin-top: 0px !important;
    }
}

@media (min-width: 481px) and (max-width: 575.98px) {
    .otzivAudioCard {
        width: 420px !important;
        height: 510px !important;
    }
    
    .otzivAudioCardLi {
        width: 420px !important;
        height: 510px !important;
    }

    .otzivAudioDescription {
        width: 100%;
        height: 170px;
        text-align: center;
    }

    .otzivAudioTitle {
        text-align: center;
    }

    .otzivMobileCardMargin {
        margin-top: 0px !important;
    }
}

@media (min-width: 320px) and (max-width: 480.98px) {

    .otzivAudioCard {
        width: 330px !important;
        height: 510px !important;
        padding: 20px 10px !important;
    }
    
    .otzivAudioCardLi {
        width: 330px !important;
        height: 510px !important;
        margin: 20px 10px 20px 10px !important;
    }

    .otzivAudioDescription {
        width: 100%;
        height: 170px;
        text-align: center;
    }

    .otzivAudioTitle {
        text-align: center;
    }

    .otzivMobileCardMargin {
        margin-top: 0px !important;
    }

    .otzivMobileCardMarginImg {
        width: 180px;
        height: 180px;
    }

}

@media (min-width: 320px) and (max-width: 639px) {
    .Fon {
        background: none;
    }
}