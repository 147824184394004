.OtzivMapHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
}

.OtzivMapHeader:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.OtzivMapText {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    text-decoration-line: underline !important;
    color: #000000 !important;
}

.OtzivMapBox {
    height: 45px;
}

.OtzivMapOverflow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    margin-top: 20px;
}

.OtzivMapOverflow::-webkit-scrollbar {
  display: none;
}

.OtzivMapOverflow::-webkit-scrollbar-track {
   display: none;
}
 
.OtzivMapOverflow::-webkit-scrollbar-thumb {
   display: none;
}

.OtzivMapOverflow::-webkit-scrollbar-thumb:hover {
   display: none;
}

.OtzivMapContainer {
    display:inline-block;
    margin: 20px;
    margin-left: 0;
    margin-right: 20px;
}
