 .salemodal {
    display: none;
    position: fixed;
    z-index: 100000;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
  }

  .salemodal {
    margin: 0 auto;
  }
  
  .modalContentClose {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  .sliderYellow {
    color: #F5E600;
  }
  
  .saleclose {
    color: #000;
    top: 0;
    float: right;
    font-size: 26px;
    font-weight: 400;
    margin: 0;
    z-index: 101;
    right: 0;
  }
  
  .saleclose:hover,
  .saleclose:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .saleContent {
    background-color: #fff;
    padding: 0 20px 0px 20px;
    width: 900px;
    min-height: 530px;
    position: relative;
    margin: auto;
  
    background-image: url("/assets/img/sale.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
  
  }
  
  .saleImgBackground {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;                 
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  .saleContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  }
  
  .saleLeft {
    display: flex;
    flex-direction: column;
    width: 660px;
    z-index: 100;
    padding-top: 0px;
  }
  
  .saleText {
    display: none;
    width: 560px;
    margin-left: 20px;
    margin-top: 120px;
  }
  
  .saleText p {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    background-color: rgba(0,0,0,.5);
    padding: 0 5px;
    margin-top: 0px;
    display: inline-block;
    text-align: center;
  }
  
  .saleRight {
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 240px;
  }
  
  .saleTitle {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 1.3;
    margin: 0;
  }
  
  .saleTimerTitle {
    color: #fff;
    margin: 0;
    font-size: 13px;
    padding: 0 5px;
    margin-top: 5px;
    text-align: center;
  }
  
  .saleTimer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
  }
  
  .saleTimerBox {
    width: 60px;
    height: 70px;
  }
  
  .saleTimerFon {
    width: 60px;
    height: 50px;
    border-radius: 5px;
    background-color: #232933;
  }
  
  .saleTimerFon p {
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    padding-top: 8px;
    margin: 0;
  }
  
  .saleTimerText {
    text-align: center;
    font-size: 12px;
    color: #fff;
    margin: 0;
  }
  
  .saleRazdel span {
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    display: inline-block;
    margin-top: 0px;
    padding: 0 2px;
  }
  
  
  input.contactFormName {
    width: 225px;
    height: 35px;
    color: #848a90;
    font-size: 14px;
    border-style: none;
    background-color: #f5f6f6;
    border-radius: 5px;
    padding-left: 15px;
  }
  
  input.contactFormCall {
    width: 225px;
    height: 35px;
    color: #848a90;
    font-size: 13px;
    border-style: none;
    background-color: #f5f6f6;
    border-radius: 5px;
    padding-left: 15px;
  }
  
  .formBox {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .formBox input {
    margin: 5px;
  }
  
  input.contactFormName::placeholder {
    color: #848a90;
    font-size: 13px;
  }
  
  input.contactFormCall::placeholder {
    color: #848a90;
    font-size: 13px;
  }
  
  .callForm p.contactFormPolicy {
    font-size: 10px;
    color: #fff;
    text-align: center;
  }
  
  p.contactFormPolicy a {
    font-size: 10px;
    color: #fff;
    text-decoration: underline;
  }
  
  .contactFormButton {
    background: linear-gradient(180deg, #FFCA2C -6.25%, rgba(240, 158, 0, 0) 558.75%);
    text-shadow: 0px 1px 1px #FFFFFF;
    color: #000;
    width: 240px;
    height: 45px;
    font-size: 22px;
    font-weight: bold;
    border-style: none;
    border-radius: 5px;
    margin: 0 auto;
    margin: 20px 5px 0 5px;
    cursor: pointer;
    transition: .3s ease;
    margin-top: 0;
  }
  
  .saleClose {
    background-color: #232933;
    color: #fff;
    width: 240px;
    height: 35px;
    font-size: 14px;
    font-weight: bold;
    border-style: none;
    margin-top: 5px;
    font-weight: bold;
    border-radius: 5px;
    margin: 0 auto;
    margin: 10px 5px 0 5px;
    cursor: pointer;
    transition: .3s ease;
  }
  
  .contactFormButton:hover {
    background: #000;
    color: #fff;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
  
  .saleClose:hover  {
    background: #f5f6f6;
    color: #000;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
  
/*==========  Media ==========*/
@media (min-width: 768px) and (max-width: 991.98px) { 
    .saleContent {
      background-color: #fff;
      padding: 0 20px 0px 20px;
      border-radius: 5px;
      width: 768px;
      height: 500px;
      position: relative;
      margin: auto;
    }
  
    .saleLeft {
      display: flex;
      flex-direction: column;
      width: 528px;
      z-index: 100;
      padding-top: 0px;
    }
  
    .saleImgBackground {
      position: absolute;
      background-color: rgba(black, 0.4);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;              
      width: 100%;
      height: 100%;
    }
  
    .saleText {
      width: 440px;
      margin-left: 20px;
    }
  
    .saleRight {
      z-index: 100;
      display: flex;
      flex-direction: column;
      width: 240px;
    }
}