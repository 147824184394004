.footerBackground {
    background: url(/assets/img/footer.png) center no-repeat;
    background-size: cover;
}

.footerText {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0 !important;
    margin-top: 15px !important;
}

.footerImages {
    margin-top: 15px !important;
}

.footerLink {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-top: 10px;
    transition: .3s ease;
}

.footerLink:hover {
    text-decoration: none;
    color: #0086FF;
    text-decoration-line: underline;
}

.footerSvgText {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    margin: 0 !important;
    margin-top: 4px !important;
    transition: .3s ease;
    width: 215px;
}

.footerSvgText:hover {
    text-decoration: none;
    color: #0086FF;
}

.footerOOCC {
    position: relative;
    margin-top: 20px;
    align-items: center;
}

.footerOOCCLogo {
    width: 45px !important;
    height: 45px !important;
}

.footerH {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.15em;
    color: #FFFFFF;
}

.footerYf1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    transition: .3s ease;
}

.footerRight {
    margin-right: 15px !important;
    width: 15px !important;
    height: 15px !important;
}

.footerSection {
    color: #0086FF !important;
}

.footerYf1:hover {
    text-decoration: none;
    color: #0086FF;
}


.footerYf {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-top: 15px;
    transition: .3s ease;
}

.footerYf:hover {
    text-decoration: none;
    color: #0086FF;
}

.footerContact1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 120%;
    letter-spacing: 0.15em;
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
}

.footerContact2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    margin-top: 3px !important;
    transition: .3s ease;
}

.footerContact2:hover {
    text-decoration: none;
    color: #0086FF;
}

.footerContact3 {
    margin: 0 !important;
    margin-top: 15px !important;
}

.footerContact31 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-top: 6px !important;
    transition: .3s ease;
}

.footerContact31:hover {
    text-decoration: none;
    color: #0086FF;
}

.footerContact4 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-top: 15px;
}

.footerContact5 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    /* identical to box height, or 16px */
    letter-spacing: 0.1em;
    color: #FFFFFF;
    transition: .3s ease;
}

.footerContact5:hover {
    text-decoration: none;
    color: #0086FF;
}

.footerContact6 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-top: 15px;
}

.footerContact7 {
    margin: 0;
    margin-top: 20px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}

.footerContact8 {
    margin: 7px;
}

.footerButton {
    width: 200px;
    height: 30px;
    background: #F4F5F9;
    color: #383434 !important;
    border-radius: 6px;

    font-family: "Rubik", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 12px !important;
    letter-spacing: 0.1em;
    border-style: none;
    cursor: pointer;
    transition: .3s ease;
    margin-top: 20px;
}

.footerButton:hover {
    background: #0086FF;
    color: #FFFFFF !important;
    text-decoration: none;
}

.footerText2 {
    margin: 0;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 10px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    color: #FFFFFF !important;
    margin-top: 0px;
    text-transform: none !important ;
    display: inline-block !important;
}

.footerText2 a {
    margin-top: 0;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 10px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
    margin-top: 10px;
    text-transform: none !important ;
}