.otzivVideoHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
}

.otzivVideoHeader:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.otzivVideoOverflow {
     flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    margin-top: 30px;
}

.otzivVideoOverflow::-webkit-scrollbar {
  display: none;
}

.otzivVideoOverflow::-webkit-scrollbar-track {
   display: none;
}
 
.otzivVideoOverflow::-webkit-scrollbar-thumb {
   display: none;
}

.otzivVideoOverflow::-webkit-scrollbar-thumb:hover {
   display: none;
}


.otzivVideoButtonL {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
    border-radius: 50% !important;
    color: #000 !important;
    position: absolute !important;
    left: -65px !important;
}

.otzivVideoButtonR {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
    border-radius: 50% !important;
    color: #000 !important;
    position: absolute !important;
    right: -65px !important;
}

.otzivLi {
    margin-right: 20px !important;
    margin: auto;
    display:inline-block;
}

@media (min-width: 320px) and (max-width: 960px) {
    .otzivVideoButtonL {
        background-color: #fff !important;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
        border-radius: 50% !important;
        color: #000 !important;
        position: absolute !important;
        left: -20px !important;
    }
    
    .otzivVideoButtonR {
        background-color: #fff !important;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important;
        border-radius: 50% !important;
        color: #000 !important;
        position: absolute !important;
        right: -20px !important;
    }
}