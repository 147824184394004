/* Modal */
.modal {
  display: none;
  position: fixed;
  z-index: 100000;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
}

.modal-content-close {
  display: flex;
  justify-content: flex-end;
}

.quiz-content {
  background-color: #fff;
  border-radius: 5px;
  width: 900px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 100;
}

.quizfon {
  position: absolute;
  border-radius: 10px;
  z-index: 0;
  width: 100%;
  height: 105%;
}

.quize-close-button {
  width: 900px;
  z-index: 100;
}

 .quize-left {
   width: 900px;
   height: 100%;
   min-height: 100%;
   display: flex;
   flex-direction: column;
   flex: auto;
   z-index: 10;
}

.quize-left hr {
  margin: 0;
}

svg.icon.icon-qcheck-form {
  margin-right: 10px;
}

.quize-progress-bar {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.quize-progress-bar-title p {
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  margin: 0;
}

.quize-progress-bar-title span {
  color: #0086ff;
  font-size: 14px;
  padding-left: 5px;
}

.quize-progress-bar-line {
  margin-top: 5px;
}

.quize-question {
  margin: 0;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 28px;
  font-weight: 500;
  color: #363636;
}

/* Progress line */
@for $i from 0 through 100 {
  .progress progress[value="#{$i}"]
  {
    & + .progress-value:before { content : '#{$i}%' }
    & ~ .progress-bg .progress-bar { width: $i * 1% }
  }
}

.progress {
  position: relative;
  overflow: hidden;

progress {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  left: -777px;
}
}

.progress-value {
  color: #333;
  display: block;
  line-height: 21px;
  text-align: center;
}

.progress-bg {
  background: #e6e9ed;
  position: relative;
  height: 8px;
  border-radius: 5px;
  overflow: hidden;
}

@keyframes progress_bar {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -40px 0;
  }
}

.progress-bar {
  overflow: hidden;
  background: #0086ff;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  transition: width 1s linear;    
}

.progress-bar:after {
  animation: progress_bar 0.8s linear infinite;      
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 40px 40px;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
}

/* Progress line */
.quize-answers-container {
  flex: auto;
}

.quize-answers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 30px 20px 30px;
  width: 840px;
  flex: auto;
}

 .quize-answer {
   display: flex;
   align-items: center;
   width: 375px;
   border: 1px solid rgba(217, 216, 230, 0.55);
   border-radius: 5px;
   padding: 10px;
   margin-bottom: 20px;
   cursor: pointer;
   user-select: none;
   position: relative;
}

.quize-answer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

span.quize-check {
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e8e8e8;
  border-radius: 50%;
  margin-left: 10px;
}

span.quize-answer-text {
  color: #232323;
  padding-left: 15px;
  width: 325px;
}

.quize-answer:hover input ~ .quize-check {
  background-color: #ccc;
}

.quize-answer input:checked ~ .quize-check {
  background-color: #0086ff;
}

.quize-check:after {
  content: "";
  position: absolute;
  display: none;
}

.quize-answer input:checked ~ .quize-check:after {
  display: block;
}

 .quize-answer .quize-check:after {
   top: 17px;
   left: 26px;
   width: 8px;
   height: 8px;
   border-radius: 50%;
}

.quize-buttons {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.quize-back {
  visibility: hidden;
  color: #4a505e;
  background-color: #fff;
  width: 140px;
  height: 40px;
  border-radius: 25px;
  box-shadow: 0 3px 13px 0 rgba(0,0,0,.13);
  border-style: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  position: relative;
  padding-left: 30px;
}

.quize-back::before {
  left: 25px;
  top: 12px;
  position: absolute;
  content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE4cHgiIGhlaWdodD0iMThweCIgdmlld0JveD0iMCAwIDQwMC4wMDQgNDAwLjAwNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAwLjAwNCA0MDAuMDA0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMzgyLjY4OCwxODIuNjg2SDU5LjExNmw3Ny4yMDktNzcuMjE0YzYuNzY0LTYuNzYsNi43NjQtMTcuNzI2LDAtMjQuNDg1Yy02Ljc2NC02Ljc2NC0xNy43My02Ljc2NC0yNC40ODQsMEw1LjA3MywxODcuNzU3ICAgYy02Ljc2NCw2Ljc2LTYuNzY0LDE3LjcyNywwLDI0LjQ4NWwxMDYuNzY4LDEwNi43NzVjMy4zODEsMy4zODMsNy44MTIsNS4wNzIsMTIuMjQyLDUuMDcyYzQuNDMsMCw4Ljg2MS0xLjY4OSwxMi4yNDItNS4wNzIgICBjNi43NjQtNi43Niw2Ljc2NC0xNy43MjYsMC0yNC40ODRsLTc3LjIwOS03Ny4yMThoMzIzLjU3MmM5LjU2MiwwLDE3LjMxNi03Ljc1MywxNy4zMTYtMTcuMzE1ICAgQzQwMC4wMDQsMTkwLjQzOCwzOTIuMjUxLDE4Mi42ODYsMzgyLjY4OCwxODIuNjg2eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzRhNTA1ZSIgZmlsbD0iIzRhNTA1ZSIvPgo8L2c+PC9nPiA8L3N2Zz4K);
}

svg.icon.icon-qleft-arrow {
  margin-right: 5px;
}

svg.icon.icon-qcheck {
  margin-right: 5px;
}

.quize-next {
  color: #fff;
  background-color: #0086ff;
  width: 140px;
  height: 40px;
  border-radius: 25px;
  box-shadow: 0 6px 21px 0 #0086ff;
  border-style: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  position: relative;
  padding-left: 30px;
}

.quize-next::before {
  left: 25px;
  top: 12px;
  position: absolute;
  content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjE4cHgiIHZpZXdCb3g9IjAgMCAzODQgMzg0IiB3aWR0aD0iMThweCI+PGc+PHBhdGggZD0ibTE5MiAzODRjMTA1Ljg2MzI4MSAwIDE5Mi04Ni4xMjg5MDYgMTkyLTE5MiAwLTE4LjI3MzQzOC0yLjU1MDc4MS0zNi4yODEyNS03LjYwMTU2Mi01My41MjczNDQtMi40ODgyODItOC40ODA0NjgtMTEuMzQzNzUtMTMuMzUxNTYyLTE5Ljg0NzY1Ny0xMC44NjMyODEtOC40ODgyODEgMi40ODA0NjktMTMuMzQzNzUgMTEuMzY3MTg3LTEwLjg2MzI4MSAxOS44NDc2NTYgNC4xODM1OTQgMTQuMzI4MTI1IDYuMzEyNSAyOS4zMjAzMTMgNi4zMTI1IDQ0LjU0Mjk2OSAwIDg4LjIyMjY1Ni03MS43NzczNDQgMTYwLTE2MCAxNjBzLTE2MC03MS43NzczNDQtMTYwLTE2MCA3MS43NzczNDQtMTYwIDE2MC0xNjBjMzIuMDYyNSAwIDYyLjkxMDE1NiA5LjM3NSA4OS4yMDcwMzEgMjcuMTA1NDY5IDcuMzIwMzEzIDQuOTQxNDA2IDE3LjI3MzQzOCAzIDIyLjIwNzAzMS00LjMyMDMxMyA0LjkzNzUtNy4zMjgxMjUgMy4wMTE3MTktMTcuMjczNDM3LTQuMzE2NDA2LTIyLjIxMDkzNy0zMS42MDE1NjItMjEuMzA4NTk0LTY4LjYzMjgxMi0zMi41NzQyMTktMTA3LjA5NzY1Ni0zMi41NzQyMTktMTA1Ljg2MzI4MSAwLTE5MiA4Ni4xMjg5MDYtMTkyIDE5MnM4Ni4xMzY3MTkgMTkyIDE5MiAxOTJ6bTAgMCIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgZmlsbD0iI2ZmZmZmZiIvPjxwYXRoIGQ9Im0zNTYuNjg3NSAzNi42ODc1LTE2NC42ODc1IDE2NC42Nzk2ODgtNTIuNjg3NS01Mi42Nzk2ODhjLTYuMjUtNi4yNDYwOTQtMTYuMzc1LTYuMjQ2MDk0LTIyLjYyNSAwLTYuMjQ2MDk0IDYuMjUtNi4yNDYwOTQgMTYuMzc1IDAgMjIuNjI1bDY0IDY0YzMuMTI4OTA2IDMuMTI4OTA2IDcuMjE0ODQ0IDQuNjg3NSAxMS4zMTI1IDQuNjg3NXM4LjE4MzU5NC0xLjU1ODU5NCAxMS4zMTI1LTQuNjg3NWwxNzYtMTc2YzYuMjQ2MDk0LTYuMjUgNi4yNDYwOTQtMTYuMzc1IDAtMjIuNjI1LTYuMjUtNi4yNDYwOTQtMTYuMzc1LTYuMjQ2MDk0LTIyLjYyNSAwem0wIDAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIGZpbGw9IiNmZmZmZmYiLz48L2c+IDwvc3ZnPgo=);
}


.quize-next:disabled {
  cursor: not-allowed;
}

.quize-right {
  background-color: #f7f7f7;
  width: 270px;
  border-radius: 0 5px 5px 0;
}

.quize-close {
  color: #232933;
  float: right;
  font-size: 28px;
  font-weight: 300;
  margin: 0;
  margin-right: 10px;
}

.quize-close:hover,
.quize-close:focus {
  color: #0086ff;
  text-decoration: none;
  cursor: pointer;
}

.quize-logo img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.quize-header h5 {
  color: #232933;
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-top: 10px;
}

.quize-header p {
  color: #646464;
  display: block;
  width: 151px;
  margin: 0 auto;
  margin-top: 5px;
}

.quize-header hr {
  width: 230px;
  margin: 0 auto;
  margin-top: 10px;
}

p.quize-text {
  margin-top: 10px;
  width: 230px;
  font-size: 14px;
}

button.slider-button {
  background-size: 200% 100%,auto,100% 2px,100% 2px,100% 1px,100% 1px;
  background-image: linear-gradient(45deg,rgba(255,255,255,.0) 30%,rgba(255,255,255,.8),rgba(255,255,255,.0) 70%);
  background-repeat: no-repeat;
  background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  animation: line 3s ease reverse infinite;
}

@keyframes line {
  100% {
    background-position: 500% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }

  20% {
    background-position: 200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }

  0% {
    background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }
}

// QUize form
.quize-form-container {
  padding: 30px;
  padding-top: 10px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.quize-form-icon {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
}

.quize-form-icon-title {
  position: relative;
}

.quize-form-icon-title:before {
  left: 0px;
  top: -30px;
  position: absolute;
  content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iMzJweCI+PGc+PHBhdGggZD0ibTM2OS4xNjQwNjIgMTc0Ljc2OTUzMWM3LjgxMjUgNy44MTI1IDcuODEyNSAyMC40NzY1NjMgMCAyOC4yODUxNTdsLTEzNC4xNzE4NzQgMTM0LjE3NTc4MWMtNy44MTI1IDcuODA4NTkzLTIwLjQ3MjY1NyA3LjgwODU5My0yOC4yODUxNTcgMGwtNjMuODcxMDkzLTYzLjg3NWMtNy44MTI1LTcuODA4NTk0LTcuODEyNS0yMC40NzI2NTcgMC0yOC4yODEyNSA3LjgwODU5My03LjgxMjUgMjAuNDcyNjU2LTcuODEyNSAyOC4yODEyNSAwbDQ5LjczMDQ2OCA0OS43MzA0NjkgMTIwLjAzMTI1LTEyMC4wMzUxNTdjNy44MTI1LTcuODA4NTkzIDIwLjQ3NjU2My03LjgwODU5MyAyOC4yODUxNTYgMHptMTQyLjgzNTkzOCA4MS4yMzA0NjljMCAxNDEuNTAzOTA2LTExNC41MTU2MjUgMjU2LTI1NiAyNTYtMTQxLjUwMzkwNiAwLTI1Ni0xMTQuNTE1NjI1LTI1Ni0yNTYgMC0xNDEuNTAzOTA2IDExNC41MTU2MjUtMjU2IDI1Ni0yNTYgMTQxLjUwMzkwNiAwIDI1NiAxMTQuNTE1NjI1IDI1NiAyNTZ6bS00MCAwYzAtMTE5LjM5NDUzMS05Ni42MjEwOTQtMjE2LTIxNi0yMTYtMTE5LjM5NDUzMSAwLTIxNiA5Ni42MjEwOTQtMjE2IDIxNiAwIDExOS4zOTQ1MzEgOTYuNjIxMDk0IDIxNiAyMTYgMjE2IDExOS4zOTQ1MzEgMCAyMTYtOTYuNjIxMDk0IDIxNi0yMTZ6bTAgMCIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgZmlsbD0iIzAwODZmZiIvPjwvZz4gPC9zdmc+Cg==);
}

svg.icon.icon-done {
  margin: 0 auto;
}

.quize-form-title {
  display: flex;
  flex-direction: column;
}

.quize-form-title h3 {
  color: #000;
  font-weight: 500;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}

.quize-form-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.quize-form-end {
  text-align: center;
}

.quize-form-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.quize-form-left p {
  color: #2e2e54;
  line-height: 1;
  padding: 0 10px 0 10px;
}

.quize-form-right {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.quize-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.quize-form label {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.2px;
}

.quize-form-input{
  margin-top: 15px;
}

.quize-form-input2 {
  width: 355px !important;
}

input.quize-form-phone {
  margin-top: 5px;
  padding: 15px 0;
  font-size: 16px;
  border-radius: 5px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMzQ4LjA3NyAzNDguMDc3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNDguMDc3IDM0OC4wNzc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz4gIDxnPiAgICA8Zz4gICAgICA8cGF0aCBkPSJNMzQwLjI3MywyNzUuMDgzbC01My43NTUtNTMuNzYxYy0xMC43MDctMTAuNjY0LTI4LjQzOC0xMC4zNC0zOS41MTgsMC43NDRsLTI3LjA4MiwyNy4wNzYgICAgIGMtMS43MTEtMC45NDMtMy40ODItMS45MjgtNS4zNDQtMi45NzNjLTE3LjEwMi05LjQ3Ni00MC41MDktMjIuNDY0LTY1LjE0LTQ3LjExM2MtMjQuNzA0LTI0LjcwMS0zNy43MDQtNDguMTQ0LTQ3LjIwOS02NS4yNTcgICAgIGMtMS4wMDMtMS44MTMtMS45NjQtMy41NjEtMi45MTMtNS4yMjFsMTguMTc2LTE4LjE0OWw4LjkzNi04Ljk0N2MxMS4wOTctMTEuMSwxMS40MDMtMjguODI2LDAuNzIxLTM5LjUyMUw3My4zOSw4LjE5NCAgICAgQzYyLjcwOC0yLjQ4Niw0NC45NjktMi4xNjIsMzMuODcyLDguOTM4bC0xNS4xNSwxNS4yMzdsMC40MTQsMC40MTFjLTUuMDgsNi40ODItOS4zMjUsMTMuOTU4LTEyLjQ4NCwyMi4wMiAgICAgQzMuNzQsNTQuMjgsMS45MjcsNjEuNjAzLDEuMDk4LDY4Ljk0MUMtNiwxMjcuNzg1LDIwLjg5LDE4MS41NjQsOTMuODY2LDI1NC41NDFjMTAwLjg3NSwxMDAuODY4LDE4Mi4xNjcsOTMuMjQ4LDE4NS42NzQsOTIuODc2ICAgICBjNy42MzgtMC45MTMsMTQuOTU4LTIuNzM4LDIyLjM5Ny01LjYyN2M3Ljk5Mi0zLjEyMiwxNS40NjMtNy4zNjEsMjEuOTQxLTEyLjQzbDAuMzMxLDAuMjk0bDE1LjM0OC0xNS4wMjkgICAgIEMzNTAuNjMxLDMwMy41MjcsMzUwLjk1LDI4NS43OTUsMzQwLjI3MywyNzUuMDgzeiIgZmlsbD0iIzVBOUZGNCIvPiAgICA8L2c+ICA8L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==);
  background-position: 20px;
  padding-left: 50px;
  background-repeat: no-repeat;

  background: #f9f9f9 !important;
  border-radius: 5px !important;
  border: none !important;
  color: #666;
}

.quize-form-phone:focus {
  outline: none !important;
  border: 2px solid #0086ff!important;
}

.quize-form-button {
  font-family: "Rubik", sans-serif !important;
  margin: 0 auto;
  margin-top: 20px;
  padding: 15px 0;
  background: linear-gradient(180deg, #0086ff -6.25%, rgba(240, 158, 0, 0) 558.75%);
  color: #fff;
  /*text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.8);*/
  box-shadow: 0 4px rgba(0, 134, 255, 0.3);
  border-style: none;
  border-radius: 5px;
  letter-spacing: 1.2px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  width: 400px ;
}

.quize-form-policy {
  text-align: center;
}

.quize-form-policy {
  padding-top: 20px;
}

.quize-form-policy p {
  font-size: 12px;
  letter-spacing: 1px;
  color: #2e2e54;
}

.quize-form-policy a {
  color: #0086ff;
}

.quize-form-policy a:hover {
  text-decoration: none;
}

.quize-title-box {
  position: relative;
  width: 70px;
}

.quize-title-box2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quize-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 20px;
}

.quize-title-svg {
  width: 10px;
  height: 10px;
  background: #52AA0D;
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  right: 1px;
  border: 2px #fff solid;
}

.quize-text-box {
  margin-left: 15px;
}

.quize-text-box-h {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    margin: 0;
}

.quize-text-box-d {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  margin: 0;
  margin-top: 2px;
  width: 200px;
}

.quize-gift-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 65px;
    background: linear-gradient(180deg, #FFCA2C -6.25%, rgba(240, 158, 0, 0) 558.75%);
    /*text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.8);*/
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #fff;
    border-style: none;
}

.quize-gift-img {
  margin-left: -20px;
}

.quize-gift-text {
  margin-left: 20px;
}

.quize-line {
    background-color: #33334F;
    background-size: 200% 100%,auto,100% 2px,100% 2px,100% 1px,100% 1px;
    background-image: linear-gradient(45deg,rgba(255,255,255,.0) 30%,rgba(255,255,255,.8),rgba(255,255,255,.0) 70%);
    background-repeat: no-repeat;
    background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
    animation: line 3s ease reverse infinite;
}

@keyframes line {
    100% {
      background-position: 500% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
    }
  
    20% {
      background-position: 200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
    }
  
    0% {
      background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
    }
}

.MapFormInput {
    outline: none !important;
    width: 330px;
    height: 55px !important;
    background: #f9f9f9 !important;
    border-radius: 5px !important;
    border: none !important;
    padding-left: 65px;
    color: #666;
    font-size: 13px !important;
    font-size: 13px !important;
    line-height: 120% !important;
    letter-spacing: 0.1em !important;
}

.MapFormInputSvg {
    margin-top: 21px;
    margin-left: 15px;
    position: absolute;
}

.MapFormInputNum {
    margin-top: 21px;
    margin-left: 45px;
    position: absolute;
    color: #666;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    font-size: 13px !important;
}

// Modal info
.quizeModalInfo.modal h4 {
  text-align: center;
}

.quizeModalInfo.modal .modal-content-description {
  padding-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.quizeModalInfo.modal .modal-content {
  background-color: #fff;
  padding: 0 20px 0px 20px;
  border: 3px solid #0086ff;
  width: 450px;
  position: relative;
  margin: auto;
}

.modal-content-title {
  margin-top: 0px;
}

/*========== Media ==========*/
@media (min-width: 320px) and (max-width: 991px) {
   .quize-form-container {
     margin: 0;
   }

   span.quize-answer-text {
      padding-left: 15px;
      width: 240px;
   }
}

@media (min-width: 768px) and (max-width: 991px) {
  .quize-form-button {
   width: 329px;
  }

  .MapFormInput {
   width: 260px;
  }
}

 @media (min-width: 992px) and (max-width: 1199.98px) {
   .quiz-content {
     width: 880px;
     flex-direction: row;
  }
   .quize-left {
     width: 630px;
  }
   .quize-answers {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: space-between;
     margin: 20px 30px 20px 30px;
     width: 820px;
  }
   .quize-answer {
     width: 370px;
  }
}
 @media (min-width: 768px) and (max-width: 991.98px) {
   .quiz-content {
     width: 750px;
     flex-direction: row;
  }
   .quize-left {
     width: 500px;
  }
   .quize-answers {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: space-between;
     margin: auto;
     margin: 20px 30px 20px 30px;
     width: 688px;
  }
   .quize-answer {
     width: 300px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
   .quize-gift-box {
      display: none;
   }

   .quiz-content {
     width: 90%;
     max-width: 690px;
     min-width: 518px;
     flex-direction: row;
   }

   .quize-answers-container {
    display: flex;
    flex-wrap: wrap;
   }

   .quize-form-right {
      width: 100%;
   }

   .quize-form-left {
      width: 100%;
   }

   .quize-answer {
     width: 100%;
   }

   .quize-left {
     width: 500px;
  }

   .quize-answers {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: space-between;
     margin: auto;
     margin: 20px 30px 20px 30px;
     width: 688px;
  }

  .MapFormInput {
   width: 330px;
  }

  .quize-form-button {
   width: 400px;
   margin: auto;
   margin-top: 25px;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
   .quize-gift-box {
      display: none;
   }

   .quiz-content {
     width: 90%;
     max-width: 535px;
     min-width: 440px;
     flex-direction: row;
   }

   .quize-answers-container {
    display: flex;
    flex-wrap: wrap;
   }

   .quize-form-right {
      width: 100%;
   }

   .quize-form-left {
      width: 100%;
   }

   .quize-answer {
     width: 100%;
   }

   .quize-left {
     width: 440px;
  }

   .quize-answers {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: space-between;
     margin: auto;
     margin: 20px 30px 20px 30px;
     width: 381px;
  }

  .MapFormInput {
   width: 290px;
  }

  .quize-form-button {
   width: 358px;
   margin: auto;
   margin-top: 25px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
   .quize-gift-box {
      display: none;
   }

   .quiz-content {
     width: 90%;
     max-width: 480px;
     min-width: 365px;
     flex-direction: row;
   }

   .quize-question {
      font-size: 24px;
   }

   .quize-answers-container {
    display: flex;
    flex-wrap: wrap;
   }

   .quize-form-right {
      width: 100%;
   }

   .quize-form-left {
      width: 100%;
   }

   .quize-answer {
     width: 100%;
   }

   .quize-left {
     width: 345px;
  }

   .quize-answers {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: space-between;
     margin: auto;
     margin: 20px 30px 20px 30px;
     width: 292px;
  }

  .MapFormInput {
   width: 210px;
  }

  .quize-form-button {
   width: 275px;
   margin: auto;
   margin-top: 25px;
  }
}
