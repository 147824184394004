.otzivBlankHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
}

.otzivBlankHeader:before  {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.Colorsection {
    background: #EBEFFF;
}

.Fon {
    background-size: 350px auto;
}

.otzivBlankOverflow {
    flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    margin-top: 30px;
}

.otzivBlankOverflow::-webkit-scrollbar {
  display: none;
}

.otzivBlankOverflow::-webkit-scrollbar-track {
   display: none;
}
 
.otzivBlankOverflow::-webkit-scrollbar-thumb {
   display: none;
}

.otzivBlankOverflow::-webkit-scrollbar-thumb:hover {
   display: none;
}

.otzivBlankButton {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    border-radius: 50% !important;
    color: #000 !important;
}

.otzivBlankImg {
    width: 270px;
    height: auto;
}

.otzivBlankLi {
    margin-right: 20px !important;
    display:inline-block;
}

@media (min-width: 320px) and (max-width: 639px) {
    .Fon {
        background: none;
    }
}