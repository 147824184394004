.cardHeaders {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
}

.cardHeaders:before  {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.estateCardLink:hover {
    text-decoration: none;
}

.estateCard {
    border-radius: 6px !important;
    width: 350px !important;
    height: 280px !important;
    padding: 25px !important;
}

.estateCardLink:hover {
    text-decoration: none;
}

.cardTitle {
    border-radius: 6px;
    color: #fff;
  
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    display: inline-block;
    padding: 7px 10px;
}
  
.cT1 {
    background: #8660FE;
  }
  
  .cT2 {
    background: #FA4B8C;
  }
  
  .cT3 {
    background: #36BEDF;
  }
  
  .cT4 {
    background: #34C15C;
  }
  
  .cT5 {
    background: #FB8854;
  }
  
  .cT6 {
    background: #6890F1;
  }

.cardHeader {
    font-family: Rubik;
    font-weight: bold;
    font-size: 16px;
    line-height: 140.1%;
    letter-spacing: 0.1em;
    color: #000000;
    width: 170px;
    margin-top: 20px;
}

.cardDescription {
    font-family: Rubik;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.1em;
    color: #9D9D9D;
    width: 170px;
    height: 110px;
    margin: 0 !important;
    margin-top: 20px !important;
}

.cardPrice {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #0086FF;
    margin: 0 !important;
    margin-top: 0px !important;
}

.cardImg {
    position: absolute;
    bottom: 0;
    right: 0;
}