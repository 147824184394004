header {
    width: 100%;
    height: auto;
    min-height: 800px;
    background: url(/assets/img/fon22.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    font-family: Rubik;
}
  
.containerText {
    margin-top: 60px;
}

.clearMarginTop {
    margin-top: 0px !important;
}
  
@media (min-width: 320px) and (max-width: 1500px) {
    .containerText {
        margin-top: 0px;
        padding-top: 0px !important;
    }

    .clearMarginTop {
        margin-top: 0px !important;
    }
}

/* Fon video */
.fullScreenDiv{
    width:100%;
    height: auto;
    padding:0!important;
    margin: 0!important;
    background-color: #fff;
    position: relative;
    background: url(/assets/img/fon22.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.video{    
    width: 100%; 
    height: auto;
    object-fit: cover;
    left: 0px;
    top: 0px;
    z-index: 1;
}
  
  @media (min-aspect-ratio: 16/9) {
    .video{
      height: 150%;
      top: -100%;
    }
  }
  
  /* The container for our text and stuff */
  .contentBox{
    position: absolute;  
    top: 0;  
    left: 0;
    width: 100%;
    height:100%;
  }
  
  .aboutFooter {
    position: absolute;  
    bottom: 0;  
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    -webkit-background-size: cover;
    background-size: cover;
  }
  
  /* section header */
  .headerMiniText {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.1em;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 5px;
    color: #8D8E8F;
  }
  
  .headerPhone {
    display: inline-block;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #33334F;
    transition: .3s ease;
  }
  
  .headerPhone:hover {
    text-decoration: none;
    color: #0086FF;
  }
  
  .headerText {
    display: inline-block;
    height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #33334F;
    transition: .3s ease;
  }
  
  .headerText:hover {
    text-decoration: none;
  }
  
  .headerLogo {
    width: 180px !important;
    flex: none !important;
  }
  
  .headerTitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    width: 575px;
    max-height: 280px;
    text-overflow: ellipsis;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    word-wrap: break-word;
  }

  .headerTitleLine {
    position: relative;
    padding-bottom: 0px;
    border-bottom: 8px solid currentColor;
    border-bottom-color: rgba(0, 134, 255, 0.1);
  }

  .ukMarginSmallTopFix {
    margin: 20px;
    margin-top: 20px;
  }

  .pText {
    width: 210px;
    height: 65px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #454545 ;
    margin-top: 10px;
  }

  .p1 {
    width: 80px;
    height: 80px;
  }

  .pImgBox {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  .pTextLink{
    display: block;
    /*background-color: rgba(255, 255, 255, 0.1);*/
    /*backdrop-filter: blur(4px);*/
    border-radius: 10px;
    padding: 20px;
  }
  
  
  .pTextLink:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.99);
  }
  
  
  @media (min-width: 320px) and (max-width: 480px) {
    .headerTitle {
      font-size: 28px;
    }
  }
  
  .headerDescription {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1em;
    /*color: rgba(255, 255, 255, 0.99);*/
    color: #454545;
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
  }
  
  .headerButton {
    font-family: Rubik;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 480px;
    height: 60px;
  
    background: #0086FF;
  
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    transition: .3s ease;
  }
  
  .headerButton:hover {
    background: #33334F;
    color: #fff;
    text-decoration: none;
  }
  
  
  .headerButton::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 410px;
    height: 75px;
    border-radius: 40px;
  }

  .headerButton2 {
    font-family: Rubik;
    display: none !important;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 480px;
    height: 60px;
    background: #0086FF;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    transition: .3s ease;
    border-style: none;
  }
  
  .headerButton2:hover {
    background: #33334F;
    color: #fff;
    text-decoration: none;
  }
  
  
  .headerButton2::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 410px;
    height: 75px;
    border-radius: 40px;
  }
  
  .buttonLine {
      background-color: #0086FF;
  
      background-size: 200% 100%,auto,100% 2px,100% 2px,100% 1px,100% 1px;
      background-image: linear-gradient(45deg,rgba(255,255,255,.0) 30%,rgba(255,255,255,.8),rgba(255,255,255,.0) 70%);
      background-repeat: no-repeat;
      background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
      animation: line 3s ease reverse infinite;
  }
  
  @keyframes line {
      100% {
        background-position: 500% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
      }
    
      20% {
        background-position: 200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
      }
    
      0% {
        background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
      }
  }

  @media (min-width: 320px) and (max-width: 959px) { 
    .headerLogoFlex {
      justify-content: center !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1160px) {
    .menImg {
      top: -120px;
      position: absolute;
      width: 380px;
      height: auto;
    }
  
    .menBox {
      margin-top: 335px;
    }
  
  }
  
  @media (min-width: 992px) and (max-width: 1080px) {
    .menImg {
      top: -95px;
      position: absolute;
      width: 360px;
      height: auto;
    }
  
    .menBox {
      position: relative;
      margin-top: 335px;
      width: 360px;
      height: 300px;
      background: rgba(30, 33, 41, 0.9);
      border-radius: 20px;
    }
  
    .menName {
      top: -25px;
      left: 100px;
      font-size: 14px;
    }
  
    .menText {
      padding-top: 15px;
      width: 265px;
      font-size: 14px;
    }
  
  }
  
  @media (min-width: 320px) and (max-width: 992px) {
    .videoDiv{
      display:none;
    }
  
    .displaynone {
      display:none;
    }
  }  

  
  @media (min-width: 576px) and (max-width: 767px) { 
    .headerButton::after {
      display: none;
      content: '';
    }

    .headerButton2::after {
        display: none;
        content: '';
      }
  }

  @media (min-width: 930px) and (max-width: 959px) {
    header {
      height: 880px;
    }
  }

  @media (min-width: 610px) and (max-width: 929px) {
    header {
      height: 1180px;
    }
  }
  
  @media (min-width: 481px) and (max-width: 575px) {
    .headerButton::after {
      display: none;
      content: '';
    }

    .headerButton2::after {
        display: none;
        content: '';
      }
  
    header {
      height: 1380px;
    }

    .marginCenter {
        justify-content: center;
        margin: auto;
    }
  }
  
  @media (min-width: 320px) and (max-width: 480px) {

    .headerButton2::after {
        display: none;
        content: '';
    }
  
    header {
      height: 1480px;
    }

    .marginCenter {
        justify-content: center;
        margin: auto;
    }
  }

  @media (min-width: 320px) and (max-width: 525px) {
    .headerButton {
      display: none !important;
    }

    .headerButton2 {
        display: flex !important;
    }

    .headerButton2 {
        width: 290px;
      }
    
    .headerButton2::before {
        width: 290px;
    }
  }

.headerContactPhone {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #33334F;
    transition: .3s ease;
}

.headerContactPhone:hover {
    color: #0086FF !important;
    text-decoration: none;
}

.headerContactPhoneDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #8D8E8F;
    margin: 0;
}

.headerContactPhoneDescriptionMargin {
    margin-top: 10px;
}

.headerContactPhoneBox {
    top: -55px !important;
    border-radius: 10px;
    padding: 20px !important;
    width: 235px !important;
}

.headerContactZvonok {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #33334F;
    width: 114px;
    border-bottom: 1px dashed #33334F;
    right: 0;
}

.headerContactZvonok:hover {
    color: #33334F;
    text-decoration: none;
}

.headerContactAddres {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #33334F;
    margin: 0;
}

.headerContactAddres:hover {
    color: #33334F;
    text-decoration: none;
}

.headerContactAddres a {
    color: #000 !important;
}

.headerContactAddres a:hover {
    color: #0086FF !important;
    text-decoration: none;
}

@media (min-width: 576px) and (max-width: 767px) {
  .headerContactPhone {
    margin-top: 5px;
  }

  .headerContactZvonok {
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .headerContactPhone {
    margin-top: 5px;
  }

  .headerContactZvonok {
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .headerContactPhone {
    margin-top: 5px;
  }

  .headerContactZvonok {
    margin: 0 auto;
    margin-top: 5px;
  }
}

.sorting-overflow {
  overflow: auto;
}

.dragscrollul {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
}

.dragscrollul::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    width: 1180px;
}

.dragscrollul::-webkit-scrollbar {
    display: none;
}

