.certificateHeader {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    position: relative;
}

.certificateHeader:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}

.certificateButton {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    border-radius: 50% !important;
    color: #000 !important;
}

.certificateImg {
    width: 270px;
    height: auto;
}

.certificateOverflow {
    flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
}

.certificateOverflow::-webkit-scrollbar {
  display: none;
}

.certificateOverflow::-webkit-scrollbar-track {
   display: none;
}
 
.certificateOverflow::-webkit-scrollbar-thumb {
   display: none;
}

.certificateOverflow::-webkit-scrollbar-thumb:hover {
   display: none;
}

.certificateLi {
    margin-right: 20px;
    display:inline-block;
}

.Fon {
    background-size: 350px auto;
}


@media (min-width: 320px) and (max-width: 639px) {
    .Fon {
        background: none;
    }
}

.mobileImg {
    visibility: visible !important;
}

@media (min-width: 320px) and (max-width: 394px) {
    .MobileImgMobile {
        margin-top: 15px;
    }
}