.contentBlogCard{
    width: 350px;
    border-radius: 6px !important;
    position: relative;
}

.contentBlogBox {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 20px,
}

.contentBlogCardLink:hover {
	text-decoration: none;
}

.contentBlogHeader {
	margin-top: 0px;
	font-family: Rubik ;
    font-style: normal;
    line-height: 120%;
    letter-spacing: .1em;
    font-weight: 700;
    font-size: 24px;
    color: #33334f;
    margin: 0;
}

.contentBlogCardTitle {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #333 !important;
    height: 70px !important;
}

.contentBlogCardDescription {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #9d9d9d !important;
    font-size: 14px !important;
    height: 150px;
}

.contentBlogCardDate {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #9d9d9d !important;
    font-size: 12px !important;
}

.contentBlogCardDateImg {
    width: 40px;
    height: 40px;
    margin-right: 10px,
}

.contentBlogImg {
    border-radius: 6px 6px 0 0;
    width: 350px;
    height: 263px;
    background-size: auto;
    background-position: 50%;
    background-repeat: no-repeat;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .contentBlogCard{
        width: 315px;
    }

   .contentBlogMobile {
	  display: flex;
	  justify-content: center;
   }
}

.contentPagination {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 30px;
}

.contentPrevBtn {
	font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #000;
    display: block !important;
    width: 85px !important;
    height: 16px !important;
    transition: .3s ease;
}

.contentPrevBtn:hover {
	text-decoration: none;
	color: #0f6ecd;
}

.contentNextBtn {
	font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #000;
    display: block !important;
    width: 85px !important;
    height: 16px !important;
    transition: .3s ease;
}

.contentNextBtn:hover {
	text-decoration: none;
	color: #0f6ecd;
}

.contentBlogCardAva {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #9d9d9d !important;
    width: 120px !important;
    flex: none !important;
}

.contentBlogCardDateImg {
    width: 48px;
    height: 48px;
}

.contentBlogCardTime {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #9d9d9d !important;
    width: 100px !important;
    flex: none !important;
    font-size: 12px;
}

.contentBlogCardDate {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #9d9d9d !important;
    font-size: 12px;
}

.contentBlogText{
    font-family: Rubik;
    font-style: normal;
    line-height: 120%;
    letter-spacing: .1em;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin: 20px 0 0;
}

.contentBlogCardTitle2 {
	display: flex;
	flex-direction: row;
	align-items: center;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    color: #333 !important;
    height: 70px !important;
}

