/* Load */

.loadBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 25px;
}

.loadBoxLink {
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    font-size: 13px !important;
    margin-right: 20px;
    text-transform: uppercase;
    color: #999;
    border-bottom: 1px solid transparent;
    transition: color .1s ease-in-out;
}

.loadBoxLink:hover {
    text-decoration: none;
    color: #000;
}

.loadBoxLinkActive {
    color: #000 !important;
    padding-bottom: 10px;
    display: block;
    position: relative;
}

.loadBoxLinkActive:before {
    position: absolute;
    content: '';
    border-bottom: 1px solid #0086FF;
    width: 100%;
    display: block;
    bottom: 0px;
}

.load-box {
    margin-top: 20px;
    margin-right: 40px;
    display: block;
}

.load-box:hover {
    text-decoration: none;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .load-box {
        margin-right: 20px;
    }
}


.load-img {
    width: 193px;
    height: 193px;
    border-radius: 6px;
    background-size: cover;
    object-fit: cover;
}

.load-price {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    font-size: 14px !important;
    color: #000 !important;
    margin-top: 15px;
    margin-bottom: 0;
    width: 193px;
}

.load-title {
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    font-size: 12px !important;
    color: #000 !important;
    margin-top: 5px;
    margin-bottom: 0;
    width: 193px;
    white-space: normal;
}

.load-address {
    margin-top: 5px;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    font-size: 10px !important;
    color: #9d9d9d;
    width: 193px;
    white-space: normal;
}

.loadHeaderH2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    margin: 0;
    position: relative;
}

.loadHeaderH2:before {
    content : "";
    position: absolute;
    left    : 0px;
    bottom  : -10px;
    height  : 1px;
    width   : 50px; 
    border-bottom: 4px solid #0086FF;
}


.loadButton {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    border-radius: 10px;
    padding: 8px 13px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0086FF;
    background: rgba(0, 134, 255, 0.07);
}

.loadButton:hover {
    text-decoration: none;
    color: #0086FF;
}

.loadButtonSvg {
    margin-left: 8px;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .loadButton {
        margin-top: 10px;
    }
}

.loadLink {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    border-radius: 10px;
    padding: 8px 13px;
    color: #0086FF;
    border: 1px solid #0086FF;
    transition: .3s ease;
}

.loadLink:hover {
    text-decoration: none;
    color: #0086FF;
}

/* Load */
.slideBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
}

.modal-title {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    margin: 0;
}

#modal-address {
    margin-left: 5px;
}

.modal-address {
    font-family: Rubik;
    margin: 0;
    margin-top: 15px;
    font-weight: 500;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
}

.slideFon {
    border-radius: 10px;
    padding: 0px;
    margin-top: 0;
    margin-left: 0px;
    position: relative;
    margin-right: 15px;
    width: 47%;
}

.slideFon2 {
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 25px 15px 35px 15px;
    margin-left: 15px;
    margin-top: 0;
    width: 47%;
}

@media (min-width: 320px) and (max-width: 1089.98px) {
    .slideFon {
        width: 100%;
        margin-right: 15px;
    }

    .slideFon2 {
        width: 100%;
        margin-top: 25px;
        margin-left: 0px;
    }
}

.uk-slideshow-items {
    min-height: 370px;
}

.load-images {
    object-fit: cover;
    width: 100%;
    height: 370px;
    border-radius: 10px;
}

.sliderPhotoCount {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 26px;
}

.sliderPhotoCountText  {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    margin-top: 0px;
    margin-left: 3px;
}

.sliderBonusYslugi {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #fff;
    color: #33334F;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
}

.sliderPhotoCountText2 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #33334F;
    margin-top: 0px;
    margin-left: 3px;
}

#prev-slide {
    font-weight: 300;
    font-size: 48px;
}

#prev-slide:hover {
    text-decoration: none;
}

#next-slide {
    font-weight: 300;
    font-size: 48px;
}

#next-slide:hover {
    text-decoration: none;
}

.slidePrice {
    margin: 0;
    margin-top: 15px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    width: 230px;
    min-height: 22px;
    max-height: 44px;
    position: relative;
}

.slidePriceM2 {
    margin: 0;
    margin-top: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #938e8e;
    width: 130px;
    height: 22px;
}

.slideBoxText5 {
    margin: 0;
    margin-top: 6px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #938e8e;
    width: 140px;
    text-align: right;
}

.slideIpotekaBox2 {
    width: 230px;
    margin-top: 5px;
}

.slideBoxAva {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 5px double #fff;
    background: #0086FF;
    padding: 0px;
}

.slideBoxText3 {
    margin: 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #000000;
}

.slideBoxText2 {
    margin: 0;
    margin-top: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #938e8e;
}

.slideChat {
    margin: 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #fff;
    background: #33334F;
    border-radius: 6px;
    width: 90px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 6px;
    margin-top: 5px;
    transition: .3s ease;
}

.slideChat:hover {
    text-decoration: none;
    color: #fff;
}

.NovostrojkiChat {
    margin: 0;
    background: #0086ff;
    border-radius: 6px;
    width: 40px;
    height: 32px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    transition: .3s ease;
    margin-left: 10px;
}

.slideCashback2 {
    position: absolute;
    top: -10px;
    right: -40px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
    background: linear-gradient(135deg, #1e87f0 0%, #33334f 100%);
    border-radius: 30px 30px 30px 0;
    padding: 3px 12px;
    display: flex;
    width: 105px;
}

.slideCashback2:hover {
    color: #fff;
    text-decoration: none;
}

.calc-info-box2 {
    background: #33334F;
    border-radius: 10px;
    top: 30px;
    width: 320px;
    display: none;
    padding: 20px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #fff;
}

.sliderLine {
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

.slideIpotekaPrice {
    margin: 0;
    margin-top: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    width: 230px;
}

.slideIpotekaPrice2 {
    color: #0086FF;
    background: rgba(0, 134, 255, 0.07);
    border-radius: 5px;
    padding: 3px 5px;
    font-weight: 500;
}

.slideBoxText2 {
    margin: 0;
    margin-top: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #938e8e;
}

.slideIpotekaBox {
    width: 230px;
    margin-top: 20px;
}

.TextSvg {
    margin-bottom: 3px;
}

.slideBoxText {
    margin: 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.1em;
    color: #000000;
}

.slideBoxText2 {
    margin: 0;
    margin-top: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.1em;
    color: #938e8e;
}

.sliderSocial {
    background: #0086ff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 25px;
    transition: .3s ease;
}

.sliderSocial:hover {
    background: #33334F;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .sliderSocials {
        margin-top: 10px;
    }
}

.sliderSocialBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.ItemNovInfoFon {
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 15px;
    margin-top: 15px;
    padding: 25px !important;
    margin-left: 0px;
    margin-top: 25px;
}

.itemNovText2 {
    margin: 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
    line-height: 20px;
}

.showBoxLink {
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    letter-spacing: .1em !important;
    font-size: 13px !important;
    margin-right: 20px;
    text-transform: uppercase;
    color: #999;
    border-bottom: 1px solid transparent;
    transition: color .1s ease-in-out;
}

.showBoxLink:hover {
    text-decoration: none;
    color: #000;
}

.headerSearchBoxLinkActive {
    color: #000 !important;
    padding-bottom: 10px;
    display: block;
    position: relative;
}

.headerSearchBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    margin-top: 40px;
}

.ItemNovInfoBox1 {
    margin-top: 10px;
}

.ItemNovInfoBox {
    margin-top: 20px;
}

.ItemNovInfoBoxText {
    margin: 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #9D9D9D;
}

.ItemNovInfoBoxDescription {
    margin: 0;
    margin-top: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #000000;
}

.ItemNovMapCenter {
    display: none;
}

.ItemNovMap {
    width: 100% !important;
    height: 400px !important;
    margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480.98px) {
    .sliderContainerMobile {
        width: 90%;
    }

    .slideFon {
        margin-right: 0px;
    }

    .ItemNovInfoFon {
        margin: 0;
        margin-top: 25px;
        margin-bottom: 15px;
    }

    .loadLR {
        margin-top: 30px;
    }
}


