#stado {
    display: none;
    animation: showNav 1000ms ease-in-out both;
    z-index: 100;
}

@keyframes showNav {
    from {opacity: 0;}
    to {opacity: 1;}
}

.stadoBox{
  width: 310px;
  height: 93px;
  background-color: #fff;
  color: #000;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(0,0,0,.35);
  margin-left: 10px;
  position: fixed;
  left: 8%;
  bottom: 15%;
  z-index:999;
  cursor:pointer;
  padding: 7px;
}

.stadoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 93px;
}

.stadoBoxImg {
    border-radius: 50%;
    margin-left: 5px;
}

.stadoContent {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  position: relative;
}

.stadoTitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    margin: 0;
}

.stadoDescription {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    margin: 0;
    margin-top: 2px;
    width: 190px;
}

.stadoClose {
    position: absolute;
    top: -6px;
    right: 2px;
}